<template>
  <v-flex>
    <div>
      <v-text-field
        class="def-input"
        v-bind="$attrs"
        :value="value"
        dense
        @input="triggerEvent"
      />
    </div>
  </v-flex>
</template>

<script>
export default {
  name: "DefaultInput",
  props: {
    value: { type: String, default: '' },
  },
  methods: {
    triggerEvent(event) {
      this.$emit('input', event);
    }
  },
}
</script>

<style scoped>
.def-input{
  margin: 5px;
  max-width: 350px;
}
</style>