<template>
  <div>
    <v-navigation-drawer
      v-model="toggleDrawer"
      fixed
      floating
      bottom
      :permanent="$vuetify.breakpoint.lgAndUp"
      right
      height="210px"
      color="transparent"
      style="margin-top: 90px; overflow: visible"
    >
      <v-card
        class="pa-2"
      >
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              Transfer Tokens
            </v-list-item-title>
            <v-list-item-subtitle>
              Fill the To field and click
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              the transfer button to transfer
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              selected assets.
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-form
            class="justify-lg-space-between d-flex flex-wrap"
          >
            <DefaultInput
              v-model="safeTransfer.to"
              dense
              label="To"
            />
          </v-form>
        </v-list-item>
        <v-list-item>
          <v-btn
            class="text-capitalize"
            color="primary"
            @click="transfer()"
          >
            Transfer
          </v-btn>
        </v-list-item>
      </v-card>
    </v-navigation-drawer>
    <v-layout
      width="100%"
      class="pa-5"
    >
      <div
        v-if="web3"
        class="justify-center"
      >
        <h2 class="text-center">
          Token Transfer Tool
        </h2>
        <VDivider />
        <p>Transfer your tokens hassle free, without the need to interact with any third party contract, just select all of the assets you want to transfer, fill the "To" field and click on transfer. In order to transfer ERC1155 tokens, you will also need to fill the "Amount" field located on each token you want to transfer, this cannot be higher than the amount of editions of that specific token you are holding.</p>
        <h3 class="pt-8">
          ERC721 Tokens
        </h3>
        <VDivider />
        <v-layout
          wrap
        >
          <template
            v-for="token of userTokens"
          >
            <v-flex
              v-if="token.asset_contract.schema_name === 'ERC721'"
              :key="token.name"
              lg2
              md3
              sm3
              xs6
              class="pa-3"
            >
              <v-card>
                <v-img
                  class="elevation-2"
                  :src="token.image_url"
                >
                  <div style="background-color: #181818;">
                    <v-checkbox
                      v-model="token.selectedForTransfer"
                      dense
                      class="float-end"
                    />
                  </div>
                </v-img>
                <v-card-text class="">
                  {{ token.name ? token.name : `${token.collection.name} #${token.token_id}` }}
                </v-card-text>
              </v-card>
            </v-flex>
          </template>
        </v-layout>
        <h3 class="pt-8">
          ERC1155 Tokens
        </h3>
        <VDivider />
        <v-layout
          wrap
        >
          <template
            v-for="token of userTokens"
          >
            <v-flex
              v-if="token.asset_contract.schema_name === 'ERC1155'"
              :key="token.name"
              lg2
              md3
              sm3
              xs6
              class="pa-3"
            >
              <v-card>
                <v-img
                  class="elevation-2"
                  :src="token.image_url"
                >
                  <v-checkbox
                    v-model="token.selectedForTransfer"
                    dense
                    class="float-end"
                  />
                </v-img>
                <v-card-text class="">
                  {{ token.name ? token.name : `${token.collection.name} #${token.token_id}` }}
                </v-card-text>
                <v-card-actions>
                  <DefaultInput
                    v-if="token.asset_contract.schema_name === 'ERC1155'"
                    v-model="token.amount"
                    class="float-end"
                    type="number"
                    label="Amount"
                  />
                </v-card-actions>
              </v-card>
            </v-flex>
          </template>
        </v-layout>
        <v-btn
          v-if="account && $vuetify.breakpoint.mdAndDown"
          color="primary"
          class="text-capitalize ma-3"
          @click="toggleDrawer = !toggleDrawer"
        >
          Transfer
        </v-btn>
      </div>
    </v-layout>
  </div>
</template>

<script>

import DefaultInput from "@/components/FormComponents/Inputs/DefaultInput";
export default {
  name: "ERC1155Tools",
  components: {DefaultInput},
  data() {
    return {
      erc721:{},
      erc1155:{},
      toggleDrawer:false,
      account: null,
      contractAddress: null,
      customContract: null,
      web3: true,
      userTokens:null,
      safeTransfer:{
        to: null,
      },
    }
  },
  async mounted() {
    window.ethereum?.on('accountsChanged', () => { window.location.reload()});
    window.ethereum?.request({ method: 'eth_requestAccounts' });
    this.account = (await this.$web3.eth.getAccounts())[0];
    if (window.ethereum) {
      this.web3 = true
    }
    fetch(`https://api.opensea.io/api/v1/assets?owner=${this.account}&limit=50`)
        .then(response => response.json())
        .then(response => this.userTokens = response.assets)
  },
  methods:{
    async transfer() {
      // eslint-disable-next-line no-unused-vars
      let batch = new this.$web3.eth.BatchRequest();

      for (let element of this.userTokens){
        if (element.asset_contract.schema_name === 'ERC721') {
          if (element.selectedForTransfer) {
            this.customContract = new this.$web3.eth.Contract([
            {
              "inputs": [
                  {
                    "internalType": "address",
                    "name": "from",
                    "type": "address"
                  }, {
                    "internalType": "address",
                    "name": "to",
                    "type": "address"
                  }, {
                    "internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"
                  }, {
                    "internalType": "bytes",
                    "name": "_data",
                    "type": "bytes"
                  }
              ],
              "name": "safeTransferFrom",
              "outputs": [],
              "stateMutability": "nonpayable",
              "type": "function"
            }], element.asset_contract.address)
            let estimatedGas = await this.customContract.methods.safeTransferFrom(this.account, this.safeTransfer.to, element.token_id, '0x').estimateGas({from: this.account});
            batch.add(this.customContract.methods.safeTransferFrom(this.account, this.safeTransfer.to, element.token_id, '0x').send({
              maxFeePerGas: null,
              maxPriorityFeePerGas: null,
              gasPrice: null,
              gas: estimatedGas,
              from: this.account,
            }));
          }
        }
        else if (element.asset_contract.schema_name === 'ERC1155') {
          if (element.selectedForTransfer) {
            this.customContract = new this.$web3.eth.Contract([
                    {
                      "inputs":
                          [
                              {
                                "internalType":"address",
                                "name":"from",
                                "type":"address"
                              },{
                                "internalType":"address",
                                "name":"to",
                                "type":"address"
                              },{
                                "internalType":"uint256[]",
                                "name":"ids",
                                "type":"uint256[]"
                              },{
                                "internalType":"uint256[]",
                                "name":"amounts",
                                "type":"uint256[]"
                              },{
                                "internalType":"bytes",
                                "name":"data",
                                "type":"bytes"
                              }
                          ],
                      "name":"safeBatchTransferFrom",
                      "outputs":[],
                      "stateMutability":"nonpayable",
                      "type":"function"
                    },{
                      "inputs":
                          [
                              {
                                "internalType":"address",
                                "name":"from",
                                "type":"address"
                              },{
                                "internalType":"address",
                                "name":"to",
                                "type":"address"
                              },{
                                "internalType":"uint256",
                                "name":"id","type":"uint256"
                              },{
                                "internalType":"uint256",
                                "name":"amount",
                                "type":"uint256"
                              },{
                                "internalType":"bytes",
                                "name":"data",
                                "type":"bytes"
                              }
                          ],
                      "name":"safeTransferFrom",
                      "outputs":[],
                      "stateMutability":"nonpayable",
                      "type":"function"
                    }], element.asset_contract.address)
            let estimatedGas = await this.customContract.methods.safeTransferFrom(this.account, this.safeTransfer.to, element.token_id,element.amount, '0x').estimateGas({from: this.account});
            batch.add(this.customContract.methods.safeTransferFrom(this.account, this.safeTransfer.to, element.token_id, element.amount, '0x').send({
              maxFeePerGas: null,
              maxPriorityFeePerGas: null,
              gasPrice: null,
              gas: estimatedGas,
              from: this.account,
            }));
          }
        }
      }
    }
  }
}
</script>

<style >
.v-navigation-drawer__content{
  overflow: visible!important;
}
</style>